import { ModuleSubscription } from '@pages/automation-center/models/filter/module-subscription.enum';
import { Product } from '@core/product-configuration/models/product.enum';

export interface IAutomationsFilter {
  moduleSubscription: ModuleSubscription | null;
  moduleIds: Product[] | null;
  searchText: string | null;
}

export const initialFilter: IAutomationsFilter = {
  moduleIds: null,
  moduleSubscription: null,
  searchText: null,
};

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AutomationsService {
  private readonly httpClient = inject(HttpClient);

  getAutomations(): Observable<boolean> {
    const url = 'api/v1/automation-center/automations/any';

    return this.httpClient.get<boolean>(environment.portalApiUrl + url);
  }
}

import { IAutomationsFilter } from '@pages/automation-center/models/filter/automations-filter.interface';
import { AutomationsViewMode } from '@pages/automation-center/models/filter/automations-view-mode.enum';

export class UpdateAutomationsFilter {
  static readonly type = '[Automations Filter] Update Automations Filter';

  constructor(public filter: Partial<IAutomationsFilter>) {}
}

export class ChangeAutomationsViewMode {
  static readonly type = '[Automations Filter] Change Automations View Mode';

  constructor(public viewMode: AutomationsViewMode) {}
}

import { SortBy } from '@pages/automation-center/models/sort-by.enum';
import { IAutomationsFilter } from '@pages/automation-center/models/filter/automations-filter.interface';

export class LoadThirdPartyIntegrations {
  static readonly type = '[Third Party Integrations] Load Third Party Integrations';

  constructor(
    public request: IAutomationsFilter,
    public sorting: boolean
  ) {}
}

export class UpdateSortBy {
  static readonly type = '[Third Party Integrations] Update Sort By';

  constructor(public sortBy: SortBy) {}
}

import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AutomationsService } from '@pages/automation-center/services/automations/automations.service';
import { LoadAutomations } from '@pages/automation-center/store/actions/automations.actions';

interface IAutomationsStateModel {
  hasAutomations?: boolean;
  loading: boolean;
}
@State<IAutomationsStateModel>({
  name: 'automations',
  defaults: { loading: false },
})
@Injectable()
export class AutomationsState {
  private readonly automationsService = inject(AutomationsService);

  @Selector()
  static hasAutomations(state: IAutomationsStateModel): boolean | undefined {
    return state.hasAutomations;
  }

  @Selector()
  static loading(state: IAutomationsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadAutomations)
  loadAutomations(context: StateContext<IAutomationsStateModel>): Observable<boolean> {
    context.patchState({ loading: true });

    return this.automationsService.getAutomations().pipe(
      tap(hasAutomations => context.patchState({ hasAutomations })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IKaseyaPlatformFeaturesRequest } from '@pages/automation-center/models/kaseya-platform-features/kaseya-platform-features-request.interface';
import { IKaseyaPlatformFeature } from '@pages/automation-center/models/kaseya-platform-features/kaseya-platform-feature.type';

@Injectable({ providedIn: 'root' })
export class KaseyaPlatformFeaturesService {
  private readonly httpClient = inject(HttpClient);

  getKaseyaPlatformFeatures(request: IKaseyaPlatformFeaturesRequest): Observable<IKaseyaPlatformFeature[]> {
    const url = 'api/v1/automation-center/kaseya-platform-features/search';

    return this.httpClient.post<IKaseyaPlatformFeature[]>(environment.portalApiUrl + url, request);
  }
}

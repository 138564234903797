import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IThirdPartyIntegrationsRequest } from '@pages/automation-center/models/third-party-integrations/third-party-integrations-request.interface';
import { IThirdPartyIntegration } from '@pages/automation-center/models/third-party-integrations/third-party-integration.interface';

@Injectable({ providedIn: 'root' })
export class ThirdPartyIntegrationsService {
  private readonly httpClient = inject(HttpClient);

  getThirdPartyIntegrations(request: IThirdPartyIntegrationsRequest): Observable<IThirdPartyIntegration[]> {
    const url = 'api/v1/automation-center/third-party-integrations/search';

    return this.httpClient.post<IThirdPartyIntegration[]>(environment.portalApiUrl + url, request);
  }
}

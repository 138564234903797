import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IThirdPartyIntegration } from '@pages/automation-center/models/third-party-integrations/third-party-integration.interface';
import { ThirdPartyIntegrationsService } from '@pages/automation-center/services/third-party-integrations/third-party-integrations.service';
import { LoadThirdPartyIntegrations, UpdateSortBy } from '@pages/automation-center/store/actions/third-party-integrations.actions';
import { SortBy } from '@pages/automation-center/models/sort-by.enum';

interface IThirdPartyIntegrationsStateModel {
  integrations?: IThirdPartyIntegration[];
  sortBy: SortBy;
  loading: boolean;
  sortLoading: boolean;
}
@State<IThirdPartyIntegrationsStateModel>({
  name: 'thirdPartyIntegrations',
  defaults: {
    loading: false,
    sortLoading: false,
    sortBy: SortBy.Recommended,
  },
})
@Injectable()
export class ThirdPartyIntegrationsState {
  private readonly thirdPartyIntegrationsService = inject(ThirdPartyIntegrationsService);

  @Selector()
  static integrations(state: IThirdPartyIntegrationsStateModel): IThirdPartyIntegration[] | undefined {
    return state.integrations;
  }

  @Selector()
  static sortBy(state: IThirdPartyIntegrationsStateModel): SortBy {
    return state.sortBy;
  }

  @Selector()
  static loading(state: IThirdPartyIntegrationsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static sortLoading(state: IThirdPartyIntegrationsStateModel): boolean {
    return state.sortLoading;
  }

  @Action(LoadThirdPartyIntegrations, { cancelUncompleted: true })
  loadThirdPartyIntegrations(
    context: StateContext<IThirdPartyIntegrationsStateModel>,
    { request, sorting }: LoadThirdPartyIntegrations
  ): Observable<IThirdPartyIntegration[]> {
    const loadingType: keyof IThirdPartyIntegrationsStateModel = sorting ? 'sortLoading' : 'loading';
    context.patchState({ [loadingType]: true });

    return this.thirdPartyIntegrationsService.getThirdPartyIntegrations({ ...request, sortBy: context.getState().sortBy }).pipe(
      tap(integrations => context.patchState({ integrations })),
      finalize(() => context.patchState({ [loadingType]: false }))
    );
  }

  @Action(UpdateSortBy)
  updateSortBy(context: StateContext<IThirdPartyIntegrationsStateModel>, { sortBy }: UpdateSortBy): void {
    context.patchState({ sortBy });
  }
}

import { SortBy } from '@pages/automation-center/models/sort-by.enum';
import { IAutomationsFilter } from '@pages/automation-center/models/filter/automations-filter.interface';

export class LoadItCompleteAutomations {
  static readonly type = '[It Complete Automations] Load It Complete Automations';

  constructor(
    public request: IAutomationsFilter,
    public sorting: boolean
  ) {}
}

export class UpdateSortBy {
  static readonly type = '[It Complete Automations] Update Sort By';

  constructor(public sortBy: SortBy) {}
}

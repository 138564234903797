import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IItCompleteAutomationsRequest } from '@pages/automation-center/models/it-complete-automations/it-complete-automations-request.interface';
import { IItCompleteAutomation } from '@pages/automation-center/models/it-complete-automations/it-complete-automation.interface';

@Injectable({ providedIn: 'root' })
export class ItCompleteAutomationsService {
  private readonly httpClient = inject(HttpClient);

  getItCompleteAutomations(request: IItCompleteAutomationsRequest): Observable<IItCompleteAutomation[]> {
    const url = 'api/v1/automation-center/it-complete-automations/search';

    return this.httpClient.post<IItCompleteAutomation[]>(environment.portalApiUrl + url, request);
  }
}

import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ItCompleteAutomationsService } from '@pages/automation-center/services/it-complete-automations/it-complete-automations.service';
import { IItCompleteAutomation } from '@pages/automation-center/models/it-complete-automations/it-complete-automation.interface';
import { LoadItCompleteAutomations, UpdateSortBy } from '@pages/automation-center/store/actions/it-complete-automations.actions';
import { SortBy } from '@pages/automation-center/models/sort-by.enum';

interface IItCompleteAutomationsStateModel {
  automations?: IItCompleteAutomation[];
  sortBy: SortBy;
  loading: boolean;
  sortLoading: boolean;
}
@State<IItCompleteAutomationsStateModel>({
  name: 'itCompleteAutomations',
  defaults: {
    loading: false,
    sortLoading: false,
    sortBy: SortBy.Recommended,
  },
})
@Injectable()
export class ItCompleteAutomationsState {
  private readonly itCompleteAutomationsService = inject(ItCompleteAutomationsService);

  @Selector()
  static automations(state: IItCompleteAutomationsStateModel): IItCompleteAutomation[] | undefined {
    return state.automations;
  }

  @Selector()
  static sortBy(state: IItCompleteAutomationsStateModel): SortBy {
    return state.sortBy;
  }

  @Selector()
  static loading(state: IItCompleteAutomationsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static sortLoading(state: IItCompleteAutomationsStateModel): boolean {
    return state.sortLoading;
  }

  @Action(LoadItCompleteAutomations, { cancelUncompleted: true })
  loadItCompleteAutomations(
    context: StateContext<IItCompleteAutomationsStateModel>,
    { request, sorting }: LoadItCompleteAutomations
  ): Observable<IItCompleteAutomation[]> {
    const loadingType: keyof IItCompleteAutomationsStateModel = sorting ? 'sortLoading' : 'loading';
    context.patchState({ [loadingType]: true });

    return this.itCompleteAutomationsService.getItCompleteAutomations({ ...request, sortBy: context.getState().sortBy }).pipe(
      tap(automations => context.patchState({ automations })),
      finalize(() => context.patchState({ [loadingType]: false }))
    );
  }

  @Action(UpdateSortBy)
  updateSortBy(context: StateContext<IItCompleteAutomationsStateModel>, { sortBy }: UpdateSortBy): void {
    context.patchState({ sortBy });
  }
}

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IAutomationsFilter, initialFilter } from '@pages/automation-center/models/filter/automations-filter.interface';
import { ChangeAutomationsViewMode, UpdateAutomationsFilter } from '@pages/automation-center/store/actions/automations-filter.actions';
import { AutomationsViewMode } from '@pages/automation-center/models/filter/automations-view-mode.enum';
import { isEqual } from 'lodash-es';

interface IAutomationsFilterStateModel {
  filter: IAutomationsFilter;
  viewMode: AutomationsViewMode;
  isFilterReset: boolean;
}

@State<IAutomationsFilterStateModel>({
  name: 'automationsFilter',
  defaults: {
    filter: initialFilter,
    viewMode: AutomationsViewMode.All,
    isFilterReset: false,
  },
})
@Injectable()
export class AutomationsFilterState {
  @Selector()
  static filter(state: IAutomationsFilterStateModel): IAutomationsFilter {
    return state.filter;
  }

  @Selector()
  static viewMode(state: IAutomationsFilterStateModel): AutomationsViewMode {
    return state.viewMode;
  }

  @Selector()
  static isFilterApplied(state: IAutomationsFilterStateModel): boolean {
    return !isEqual(initialFilter, state.filter);
  }

  @Selector()
  static isFilterReset(state: IAutomationsFilterStateModel): boolean {
    return state.isFilterReset;
  }

  @Action(UpdateAutomationsFilter)
  updateAutomationsFilter(context: StateContext<IAutomationsFilterStateModel>, { filter }: UpdateAutomationsFilter): void {
    const updatedFilter = { ...context.getState().filter, ...filter };
    const isFilterReset = isEqual(updatedFilter, initialFilter);

    context.patchState({ filter: updatedFilter, isFilterReset });
  }

  @Action(ChangeAutomationsViewMode)
  changeAutomationsViewMode(context: StateContext<IAutomationsFilterStateModel>, { viewMode }: ChangeAutomationsViewMode): void {
    context.patchState({ viewMode });
  }
}

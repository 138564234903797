import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IKaseyaPlatformFeature } from '@pages/automation-center/models/kaseya-platform-features/kaseya-platform-feature.type';
import { KaseyaPlatformFeaturesService } from '@pages/automation-center/services/kaseya-platform-features/kaseya-platform-features.service';
import { LoadKaseyaPlatformFeatures } from '@pages/automation-center/store/actions/kaseya-platform-features.actions';

interface IKaseyaPlatformFeaturesStateModel {
  features?: IKaseyaPlatformFeature[];
  loading: boolean;
}
@State<IKaseyaPlatformFeaturesStateModel>({
  name: 'kaseyaPlatformFeatures',
  defaults: { loading: false },
})
@Injectable()
export class KaseyaPlatformFeaturesState {
  private readonly kaseyaPlatformFeaturesService = inject(KaseyaPlatformFeaturesService);

  @Selector()
  static features(state: IKaseyaPlatformFeaturesStateModel): IKaseyaPlatformFeature[] | undefined {
    return state.features;
  }

  @Selector()
  static loading(state: IKaseyaPlatformFeaturesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadKaseyaPlatformFeatures, { cancelUncompleted: true })
  loadKaseyaPlatformFeatures(
    context: StateContext<IKaseyaPlatformFeaturesStateModel>,
    { request }: LoadKaseyaPlatformFeatures
  ): Observable<IKaseyaPlatformFeature[]> {
    context.patchState({ loading: true });

    return this.kaseyaPlatformFeaturesService.getKaseyaPlatformFeatures(request).pipe(
      tap(features => context.patchState({ features })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
